import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';
// import Img from 'gatsby-image';

const TestSlider = () => {

    const data = useStaticQuery(graphql`
        {
            kontentItemTestssection {
                elements {
                    header {
                        value
                    }
                    text {
                        value
                    }
                    slides {
                        linked_items {
                            ... on KontentItemSwiperSlide {
                                elements {
                                    description {
                                        value
                                    }
                                    image {
                                        value {
                                            url
                                            description
                                        }
                                    }
                                }
                                featuredImg {
                                    childImageSharp {
                                        fixed(width: 100){
                                            ...GatsbyImageSharpFixed_noBase64
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        new Swiper('.test-slider', {
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            loop: true,
            loopAdditionalSlides: 4,
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 1,
            coverflowEffect: {
                rotate: 0,
                stretch: -200,
                depth: 500,
                modifier: 1,
                slideShadows: false,
            },
            lazy: {
                loadPrevNext: 1,
            },
            pagination: {
                el: '.swiper-pagination',
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                }
            },
        });
    }, [])

    return (
    <div className="au-section-two" id="test-slider">
        <div className="grid-container">
            <header>
                <h3>{data.kontentItemTestssection.elements.header.value}</h3>
                <p dangerouslySetInnerHTML={{__html: data.kontentItemTestssection.elements.text.value}}></p>
            </header>
            <div className="section-slider">
                <div className="swiper-container section-slider-swiper test-slider">
                    <div className="swiper-wrapper">
                        {data.kontentItemTestssection.elements.slides.linked_items.map((slide, index) => {
                            return (
                            <div key={index} className="swiper-slide">
                                <div className="section-slider-swiper-slide">
                                        <img className='swiper-lazy' data-src={slide.elements.image.value[0].url} alt={slide.elements.image.value[0].description} />
                                    <p>{slide.elements.description.value}</p>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default TestSlider
