import React from 'react';

const ConsentInfo = ({show, hide, data}) => {

    return (
    <div className="au-section-contact__form-agree-more">
        <span className="au-read-more" onClick={show} onKeyDown={show} role="button" tabIndex="0" >{data.read_more.value}</span>
        <main>
            <div dangerouslySetInnerHTML={{ __html: data.consent_text.value }}></div>
            <span className="au-read-less" onClick={hide} onKeyDown={hide} role="button" tabIndex="0">{data.close.value}</span>
        </main>
    </div>
    )
}

export default ConsentInfo;
