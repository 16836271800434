import React, {lazy} from "react";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero';
import SectionOne from '../components/sectionOne';
import TestSlider from '../components/testSlider';
import TrainingSection from '../components/trainingSection';
import TrainingBoxes from '../components/trainingBoxes';
import AboutSection from '../components/aboutSection';
import TrustedSection from '../components/trustedSection';
import ContactForm from '../components/contactForm';

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <SectionOne />
      <TestSlider />
      <TrainingSection />
      <TrainingBoxes />
      <AboutSection />
      <TrustedSection />
      <ContactForm />
    </Layout>
  )
}

export default IndexPage;
