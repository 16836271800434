import React, { useEffect } from 'react';
import Swiper from 'swiper';

const HeroSlider = ({slides}) => {
    
    useEffect(() => {
        new Swiper('.au-hero__swiper', {
            loop: true,
            spaceBetween: 30,
            centeredSlides: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        });
    }, [])


    return (
    <div className="swiper-container au-hero__swiper">
        <div className="swiper-wrapper">
            {slides.map((slide, index) => {
                return (
                <div key={index} className="swiper-slide">
                    <div className="au-hero__swiper-slide">
                        <div className={`warning progress progress--${slide.elements.percantage.value}`}>
                            <div className="progress-meter"></div>
                        </div>
                        <p className="au-hero__swiper-slide-number">{slide.elements.percantage.value}<span>%</span></p>
                        <p className="au-hero__swiper-slide-desc">{slide.elements.description.value}</p>
                    </div>
                </div>
                )
            })}
        </div>
    </div>
    )
}

export default HeroSlider;