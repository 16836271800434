import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const TrainingBoxes = () => {


    const { kontentItemTrainingBoxSection } = useStaticQuery(graphql`
        {
            kontentItemTrainingBoxSection {
                elements {
                    header {
                        value
                    }
                    boxes {
                        linked_items {
                            ... on KontentItemTrainingBox {
                                elements {
                                    header {
                                        value
                                    }
                                }
                                featuredImg {
                                    childImageSharp {
                                        fixed(width: 100){
                                            ...GatsbyImageSharpFixed
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className="au-section-four" id="training-boxes">
    <div className="grid-container">
        <header>
            <h3>{kontentItemTrainingBoxSection.elements.header.value}</h3>
        </header>
        <div className="section-block">
            <div className="grid-x grid-margin-x">
                {kontentItemTrainingBoxSection.elements.boxes.linked_items.map((box, index) => {
                    return (
                    <div key={index} className="cell small-12 medium-6">
                        <div className="section-block__item">
                            <div className="section-block__item-img">
                                <Img fixed={box.featuredImg.childImageSharp.fixed} />
                            </div>
                            <p>{box.elements.header.value}</p>
                        </div>
                    </div>
                    )
                })}
            </div>
        </div>
    </div>
</div>
    )
}

export default TrainingBoxes
