import React from 'react';

const HeroSection = ({data}) => {
    return (
    <>
        <h2>{data.description.value}</h2>
        <a href={data.cta_button__href.value} className="button">{data.cta_button__description.value}<i className="fas fa-chevron-down"></i></a>
    </>
    )
}

export default HeroSection
