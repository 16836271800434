import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ConsentInfo from '../components/consentInfo';
import $ from 'jquery';

const ContactForm = () => {

    const { kontentItemContactForm } = useStaticQuery(graphql`
    {
        kontentItemContactForm {
            elements {
                header {
                    value
                }
                consent_checkbox {
                    value
                }
                read_more {
                    value
                }
                close {
                    value
                }
                consent_text {
                    value
                }
            }
        }
    }
`);

    const showConsent = () => {
        $('.au-section-contact__form-agree-more main').slideDown();
    }

    const hideConsent = () => {
        $('.au-section-contact__form-agree-more main').slideUp();
    }

    // we can use netlify form mechanism
    // https://docs.netlify.com/forms/setup/
    return (
    <div className="au-section-contact" id="contact">
        <div className="grid-container">
            <header>
                <h2>{kontentItemContactForm.elements.header.value}</h2>
            </header>
            <div className="au-section-contact__form">
                <form name="contact" >
                    <div className="grid-x grid-margin-x">
                        <div className="cell medium-6">
                            <input type="text" placeholder="Imię*"/>
                        </div>
                        <div className="cell medium-6">
                            <input type="email" placeholder="Adres e-mail*"/>
                        </div>
                        <div className="cell small-12">
                            <input type="tel" placeholder="Numer telefonu"/>
                        </div>
                        <div className="cell small-12">
                            <textarea placeholder="Wiadomość*"></textarea>
                            <div className="au-section-contact__form-submit">
                                <input type="submit" className="button" value="wyślij"/>
                            </div>
                        </div>
                        <div className="cell small-12">
                            <div className="au-section-contact__form-agree">
                                <div className="pretty p-default">
                                    <input id="contact-consent" type="checkbox" />
                                    <div className="state p-warning">
                                        <label htmlFor="contact-consent">{kontentItemContactForm.elements.consent_checkbox.value}</label>
                                    </div>
                                </div>
                            </div>
                            <ConsentInfo 
                                show={showConsent}
                                hide={hideConsent}
                                data={kontentItemContactForm.elements} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default ContactForm
