import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const AboutSection = () => {

    const {kontentItemHeroContent} = useStaticQuery(graphql`
        {
            kontentItemHeroContent(system: { name: { eq: "O nas" } }) {
                elements {
                    header {
                        value
                    }
                    description {
                        value
                    }
                    cta_button__description {
                        value
                    }
                }
                featuredImg {
                    childImageSharp {
                        fluid(maxWidth: 530){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);

    return (
    <div className="au-section-five" id="about">
        <div className="grid-x">
            <div className="cell large-6">
                <div className="au-section-five__img">
                    <Img fluid={kontentItemHeroContent.featuredImg.childImageSharp.fluid} />
                </div>
            </div>
            <div className="cell large-6">
                <div className="au-section-five__text">
                    <div className="rear-item">
                        <h2>O nas</h2>
                    </div>
                    <div className="front-item">
                        <div dangerouslySetInnerHTML={{__html: kontentItemHeroContent.elements.description.value}}></div>
                        <p className="primary">{kontentItemHeroContent.elements.cta_button__description.value}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default AboutSection
