import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image'; 

const TrainingSection = () => {

    const data = useStaticQuery(graphql`
        {
            kontentItemHeroContent(system: { name: { eq: "Sekcja trening" } } ) {
                elements {
                    header {
                        value
                    }
                    description {
                        value
                    }
                    cta_button__href {
                        value
                    }
                    cta_button__description {
                        value
                    }
                }
                featuredImg {
                    childImageSharp {
                        fluid(maxWidth: 669) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);



    return (
    <div className="au-section-three" id="training">
        <div className="grid-x">
            <div className="cell large-6">
                <div className="au-section-three__text">
                    <div className="rear-item rear-item--left">
                        <h2>{data.kontentItemHeroContent.elements.header.value}</h2>
                    </div>
                    <div className="front-item front-item--left">
                        <div dangerouslySetInnerHTML={{__html: data.kontentItemHeroContent.elements.description.value}}></div>
                        <a href={data.kontentItemHeroContent.elements.cta_button__href.value} className="button">{data.kontentItemHeroContent.elements.cta_button__description.value}<i className="fas fa-chevron-down"></i></a>
                    </div>
                </div>
            </div>
            <div className="cell large-6">
                <div className="au-section-three__img au-section-three__img--right">
                    <Img fluid={data.kontentItemHeroContent.featuredImg.childImageSharp.fluid} />
                </div>
            </div>
        </div>
    </div>
    )
}

export default TrainingSection
