import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import HeroSlider from './heroSlider';
import HeroSection from './heroSection';

const Hero = () => {

    const data = useStaticQuery(graphql`
    query HeroSLidesQuery {
        kontentItemHeroBanner {
            elements {
            cta_button__description {
                value
            }
            cta_button__href {
                value
            }
            description {
                value
            }
            slides {
                linked_items {
                ... on KontentItemSwiperSlide {
                    id
                    elements {
                    description {
                        value
                    }
                    percantage {
                        value
                    }
                    }
                }
                }
            }
            }
        }
    }
    `);


    return (
    <div className="au-hero">
        <div className="grid-container">
            <div className="au-hero-container">
                <div className="grid-x">
                    <div className="cell large-6">
                        <div className="au-hero__left">
                            <HeroSection data={data?.kontentItemHeroBanner?.elements}/>
                        </div>
                    </div>
                    <div className="cell large-6">
                        <div className="au-hero__right">
                            <HeroSlider slides={data?.kontentItemHeroBanner?.elements?.slides?.linked_items} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a className="vertical-nav" href="#test"><i className="fas fa-chevron-down"></i></a>
    </div>
    )
}

export default Hero
