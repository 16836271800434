import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const SectionOne = () => {

    const data = useStaticQuery(graphql`
    query SectionOneQuery {
        kontentItemHeroContent {
            elements {
                header {
                    value
                }
                subheader {
                    value
                }
                description {
                    value
                }
                cta_button__href {
                    value
                }
                cta_button__description {
                    value
                }
            }
            featuredImg {
                childImageSharp {
                    fluid(maxWidth: 658) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    `);

    return (
    <div className="au-section-one" id="test">
        <div className="grid-x">
            <div className="cell large-6 flex-end">
                <div className="au-section-one__img">
                    <Img fluid={data.kontentItemHeroContent.featuredImg.childImageSharp.fluid} />
                </div>
            </div>
            <div className="cell large-6">
                <div className="au-section-one__text">
                    <div className="rear-item">
                        <h2>{data.kontentItemHeroContent.elements.header.value}</h2>
                        <p dangerouslySetInnerHTML={{__html: data.kontentItemHeroContent.elements.subheader.value}}></p>
                    </div>
                    <div className="front-item">
                        <div dangerouslySetInnerHTML={{__html: data.kontentItemHeroContent.elements.description.value}}></div>
                        <a href={data.kontentItemHeroContent.elements.cta_button__href.value} className="button">{data.kontentItemHeroContent.elements.cta_button__description.value}<i className="fas fa-chevron-down"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SectionOne;
