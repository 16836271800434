import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Swiper from 'swiper';

const TrustedSection = () => {

    const {kontentItemTrustedSection} = useStaticQuery(graphql`
        {
            kontentItemTrustedSection {
                elements {
                    header {
                        value
                    }
                    slides {
                        value {
                            url
                            description
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        new Swiper('.swiper-trusted', {
            loop: true,
            slidesPerView: 2,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                640: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 6,
                },
            },
        });
    }, [])


    return (
    <div className="au-section-trusted">
        <header>
            <h2>{kontentItemTrustedSection.elements.header.value}</h2>
        </header>
        <div className="au-section-trusted-slider">
            <div className="swiper-container swiper-trusted">
                <div className="swiper-wrapper">
                    {kontentItemTrustedSection.elements.slides.value.map((slide, index) => {
                        return (
                        <div key={index} className="swiper-slide">
                            <div className="swiper-trusted-slide">
                                <img src={slide.url} alt={slide.description} />
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </div>
    )
}

export default TrustedSection
